<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="submitForm"
    >

      <!-- form -->
      <b-form @submit.prevent="saveData">
        <status-alert
          :is-success="isSuccess"
          :error-data="errorData"
          :success-msg="$t('Company Info Updated')"
        />
        <b-row>
          <!-- Business Name -->
          <b-col cols="12">
            <b-form-group
              label="Business Name"
              label-for="business-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Business name"
                vid="b-business-name"
              >
                <b-form-input
                  id="business_name"
                  v-model="localOptions.business_name"
                  size="lg"
                  placeholder="Enter your business name..."
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Business Name -->
          <!-- street address -->
          <b-col cols="12">
            <b-form-group
              label="Street Address"
              label-for="street-address"
            >

              <b-form-textarea
                id="street-address"
                v-model="localOptions.street_address"
                size="lg"
                :class="streetAddressCounter >= 255 ? 'text-danger' : ''"
                :state="streetAddressCounter <= 255"
                rows="4"
                placeholder="Enter your company's street address..."
              />
              <small
                class="textarea-counter-value float-right"
                :class="streetAddressCounter >= 255 ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ streetAddressCounter }}</span> / {{ 255 }}
              </small>
            </b-form-group>
          </b-col>
          <!--/ street address -->
          <!-- Phone -->
          <b-col cols="12">
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <v-select
                    v-model="localOptions.phone_code"
                    style="min-width:50px;"
                    class="lg-input"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryOptions"
                    :reduce="val => val.phone_code"
                    label="phone_text"
                    autocomplete="chrome-off"
                    :clearable="false"
                    input-id="phone_code"
                  />
                </b-input-group-prepend>
                <cleave
                  id="phone"
                  v-model="localOptions.phone"
                  size="lg"
                  class="form-control"
                  type="tel"
                  :raw="false"
                  :options="clevePhone"
                  placeholder="Phone number"
                />
              </b-input-group>

            </b-form-group>
          </b-col>
          <!--/ Phone -->
          <!-- Email -->
          <b-col cols="12">
            <b-form-group
              label="Business Email"
              label-for="email"
            > <validation-provider
              #default="{ errors }"
              name="Email"
              vid="b-business-email"
              rules="email|required"
            >
              <b-form-input
                id="email"
                v-model="localOptions.other_email"
                size="lg"
                placeholder="Enter your email..."
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-alert
                variant="warning"
                class="mt-1"
                show
              >  <div class="alert-body ">
                <span>Note: This email is necesssarry to contact with your client when you send an email</span>
              </div>

              </b-alert>
            </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Email -->

          <!-- Website -->
          <b-col cols="12">
            <b-form-group
              label="Website"
              label-for="website"
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                vid="b-business-website"
                rules="url"
              >
                <b-form-input
                  id="website"
                  v-model="localOptions.website"
                  size="lg"
                  placeholder="Enter your website..."
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Website -->
          <b-col cols="12">
            <b-form-group
              label="GST"
              label-for="gst"
            >
              <validation-provider
                #default="{ errors }"
                name="Gst"
                vid="b-gst"
              >
                <b-form-input
                  id="gst"
                  v-model="localOptions.gst"
                  size="lg"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="WCB"
              label-for="wcb"
            >
              <validation-provider
                #default="{ errors }"
                name="wcb"
                vid="b-wbc"
              >
                <b-form-input
                  id="wbc"
                  v-model="localOptions.wbc"
                  size="lg"
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Additional information -->
          <b-col cols="12">
            <b-form-group
              label="Additional information"
              label-for="additional-information"
            >
              <b-form-textarea
                id="additional-information"
                v-model="localOptions.additional_information"
                size="lg"
                :class="additionalInformationCounter >= 255 ? 'text-danger' : ''"
                :state="additionalInformationCounter <= 255"
                rows="4"
                placeholder="Add additional information that you want to appear on your invoices..."
              />
              <small
                class="textarea-counter-value float-right"
                :class="additionalInformationCounter >= 255 ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ additionalInformationCounter }}</span> / {{ 255 }}
              </small>

            </b-form-group>
          </b-col>
          <!--/ street address -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"

              type="submit"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BInputGroup, BInputGroupPrepend, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { url, email } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BInputGroup,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BFormInput,
    BRow,
    vSelect,
    BCol,
    BCard,
    BFormTextarea,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOptions: [],
      url,
      email,
      isSuccess: false,
      errorData: null,
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  computed: {

    additionalInformationCounter() {
      let counter = 0

      if (this.localOptions.additional_information) {
        counter = this.localOptions.additional_information.length
      }
      return counter
    },
    streetAddressCounter() {
      let counter = 0

      if (this.localOptions.street_address) {
        counter = this.localOptions.street_address.length
      }
      return counter
    },

  },
  created() {
    this.fetchCountries()
  },
  methods: {
    fetchCountries() {
      store.dispatch('app/fetchCountries', { with_taxes: true })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({
            label: val.name, value: val.id, code: val.code, phone_code: val.phone_code, phone_text: `${val.code} (+${val.phone_code})`, tax_rates: val.tax_rates, states: val.states,
          }))

          this.countryOptions = arr
        })
    },
    saveData() {
      this.$refs.submitForm.validate().then(success => {
        if (success) {
          store
            .dispatch('app-account/updateCompanyInfo', this.localOptions)
            .then(response => {
              const userData = this.$user()
              userData.business_name = this.localOptions.business_name
              localStorage.setItem('userData', JSON.stringify(userData))
              store.commit('app/UPDATE_USER_DATA', userData)
              if (this.localOptions.business_name !== '') {
                this.$userHandler.setUsageStep('business_name_entered')
              }
              if (this.localOptions.street_address !== '' && this.localOptions.email !== '') {
                this.$userHandler.setUsageStep('business_info_entered')
              }
              this.userData = response.data.data
              this.isSuccess = true
              this.errorData = null
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorData = error.response.data
              }
            })
        }
      })
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
